








import Vue from 'vue';
import { Component, Prop, VModel } from 'vue-property-decorator';

@Component({})
export default class MarkdownEditor extends Vue {
  private MARKDOWN_EDITOR_MODE_PREVIEW = 'preview';
  private MARKDOWN_EDITOR_MODE_TWO_COL_LAYOUT = 'editable';
  private MARKDOWN_EDITOR_MODE_ONE_COL_LAYOUT = 'edit';
  private markdownEditorLeftToolbarConfig: string = 'undo redo clear | h bold italic | ul ol';
  private markdownEditorRightToolbarConfig: string = 'preview sync-scroll fullscreen';

  private loaded: boolean = false;
  private markdownEditorMode: string = this.MARKDOWN_EDITOR_MODE_ONE_COL_LAYOUT;

  @Prop({ default: false })
  private readonly viewOnlyMode!: boolean;

  @Prop({ default: true })
  private readonly showPreview!: boolean;

  @Prop({ default: false })
  private readonly showLinkButtonInToolbar!: boolean;

  @VModel({ type: String })
  private text!: string;

  private mounted() {
    if (this.viewOnlyMode) {
      this.markdownEditorMode = this.MARKDOWN_EDITOR_MODE_PREVIEW;
      this.loaded = true;
      return;
    }

    if (this.showPreview) {
      this.markdownEditorMode = this.MARKDOWN_EDITOR_MODE_TWO_COL_LAYOUT;
      this.onResize();
      window.addEventListener('resize', this.onResize);
    }

    if (this.showLinkButtonInToolbar) {
      this.markdownEditorLeftToolbarConfig = `${this.markdownEditorLeftToolbarConfig} | link`;
    }

    this.loaded = true;
  }

  private beforeDestroy() {
    if (this.showPreview) {
      window.removeEventListener('resize', this.onResize);
    }
  }

  private onResize() {
    this.markdownEditorMode = window.innerWidth > 640
      ? this.MARKDOWN_EDITOR_MODE_TWO_COL_LAYOUT
      : this.MARKDOWN_EDITOR_MODE_ONE_COL_LAYOUT;
  }
}
