

















import {
  Component, Emit, Prop, VModel, Vue,
} from 'vue-property-decorator';

@Component
export default class ImageLightbox extends Vue {

  @Prop({ required: true })
  private readonly imagePaths!: string[];

  @VModel({ default: 0 })
  private imageIndex!: number;

  @Emit()
  private close(event: Event) {
    event.stopPropagation();
  }

  private get showLeftArrow(): boolean {
    return this.imageIndex > 0;
  }

  private get showRightArrow(): boolean {
    return this.imageIndex < this.imagePaths.length - 1;
  }

  private getImagePathById(id: number) {
    return `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}${id}`;
  }

  private get imageSrc(): string {
    return this.imagePaths[this.imageIndex];
  }

  private previous() {
    if (this.imageIndex > 0) {
      this.imageIndex -= 1;
    }
  }

  private next() {
    if (this.imageIndex < this.imagePaths.length - 1) {
      this.imageIndex += 1;
    }
  }
}
