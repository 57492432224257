























import {
  Component, Prop, Ref, Vue,
} from 'vue-property-decorator';
import ImageLightbox from '@/components/ImageLightbox.vue';

@Component({
  components: { ImageLightbox },
})
export default class ImageGallery extends Vue {
  @Prop({ required: true })
  private readonly images!: string[];

  @Prop({ default: 'primary-orange' })
  private readonly arrowColor!: string;

  @Prop({ default: 4 })
  private readonly spaceBetweenImages!: number;

  @Ref('galleryContainer')
  private galleryContainer!: HTMLElement;

  @Ref('imageContainer')
  private imageContainer!: HTMLElement;

  @Ref('arrowRightRef')
  private arrowRef!: HTMLElement;

  private showLightbox: boolean = false;
  private lightboxStartIndex: number = 0;

  private showRightArrow = true;

  private currentLeftImageIndex = 0;
  private currentMargin: number = 0;
  private spacing: number = 0;

  private isMounted = false;
  private loadedImagesCounter = 0;
  private rightArrowTrigger: number = 1;
  private refreshDisplayRightArrow: any;

  private created() {
    this.refreshDisplayRightArrow = setInterval(() => {
      this.rightArrowTrigger = this.rightArrowTrigger > 100 ? 1 : this.rightArrowTrigger + 1;
    }, 1000);
  }

  private beforeDestroy() {
    return clearInterval(this.refreshDisplayRightArrow);
  }

  private setup() {
    this.spacing = (this.spaceBetweenImages * 0.25) * parseFloat(getComputedStyle(document.documentElement).fontSize);
    this.isMounted = true;
  }

  private imageLoaded() {
    this.loadedImagesCounter += 1;
    if (this.images && this.images.length > 0 && this.loadedImagesCounter === this.images.length) {
      this.setup();
    }
  }

  private openLightbox(imageIndex: number) {
    this.lightboxStartIndex = imageIndex;
    this.showLightbox = true;
  }

  private get arrowClasses(): string {
    return `w-12 h-12 p-0 m-0 text-${this.arrowColor}`;
  }

  private get showLeftArrow(): boolean {
    return this.currentMargin < 0;
  }

  private get displayRightArrow() {
    return this.isMounted
      && this.rightArrowTrigger !== 0
      && this.imageContainer.scrollWidth > this.galleryContainer.clientWidth
      && Math.abs(this.galleryContainer.clientWidth - this.imageContainer.scrollWidth) - Math.abs(this.currentMargin) > 0;
  }

  private getImagePathById(id: number) {
    return `${process.env.VUE_APP_PROJECT_SERVICE_IMAGE_URL}${id}`;
  }

  private next() {
    const maxMovementWidth = this.imageContainer.scrollWidth - this.galleryContainer.clientWidth;
    if (Math.abs(this.currentMargin) >= maxMovementWidth) {
      this.showRightArrow = false;
      return;
    }

    this.showRightArrow = true;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const movementWidth = this.$refs.images[this.currentLeftImageIndex].clientWidth - this.arrowRef.clientWidth + this.spacing;
    if (Math.abs(this.currentMargin - movementWidth) > maxMovementWidth) {
      this.currentMargin = -maxMovementWidth;
    } else {
      this.currentMargin -= movementWidth;
    }
    this.imageContainer.style.marginLeft = `${this.currentMargin}px`;
    this.currentLeftImageIndex += 1;
  }

  private previous() {
    if (this.currentMargin >= 0) {
      return;
    }

    const previousImageIndex = this.currentLeftImageIndex - 1;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const movementWidth = this.$refs.images[previousImageIndex].clientWidth + this.spacing - this.arrowRef.clientWidth;
    if (this.currentMargin + movementWidth > 0) {
      this.currentMargin = 0;
    } else {
      this.currentMargin += movementWidth;
    }
    this.imageContainer.style.marginLeft = `${this.currentMargin}px`;
    this.currentLeftImageIndex -= 1;
  }
}
