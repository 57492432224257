import MessageBoxType from '../messageBoxTypes';

export default class AppPermission {
  public static LOCATION = new AppPermission('Lokalisierungsdienste', 'Lokalisierungsdienste', 'style-one-pin.png', undefined, [], false);
  public static CONTACTS = new AppPermission('Kontakte', 'Kontakte', 'single-neutral-profile-picture.png', undefined, [], false);
  public static CALENDAR = new AppPermission('den Kalender', 'Kalender', 'calendar.png', undefined, [], false);
  public static NOTES = new AppPermission('Notizen', 'Notizen', 'notes.png', undefined, [], false);
  public static PHOTOS = new AppPermission('Fotos', 'Fotos', 'photo.png', undefined, [], false);
  public static BLUETOOTH = new AppPermission('Bluetooth', 'Bluetooth', 'bluetooth.png', undefined, [], false);
  public static SPEECH_RECOGNITION = new AppPermission('Spracherkennung', 'Spracherkennung', 'messages-bubble-square-typing-1.png', undefined, [], false);
  public static CAMERA = new AppPermission('die Kamera', 'Kamera', 'camera-1.png', undefined, [], false);
  public static FILESYSTEM = new AppPermission('das Dateisystem', 'Dateisystem', 'notes-paper.png', undefined, [], false);
  public static BODY_DATA = new AppPermission('Körperdaten', 'Körperdaten', 'graph-stats.png', undefined, [], false);
  public static MICROPHONE = new AppPermission('das Mikrofon', 'Mikrofon', 'microphone.png', undefined, [], false);
  public static SINGLE_SIGN_ON = new AppPermission('Daten von innovaMo für den Single-Sign-On', 'Single-Sign-On für meine Apps ermöglichen', 'sso.png', MessageBoxType.ERROR, ['Für die Einbindung des innovaMo Single-Sign-On benötigen Sie eine Client-ID und ein Client-Secret, die Sie auf dieser Seite einsehen können, nachdem Sie die Änderungen gespeichert haben'], true);
  public static SURVEYS = new AppPermission('', 'Umfragen für meine Apps ermöglichen', 'sso.png', MessageBoxType.ERROR, ['Für die Einbindung von Umfragen von innovaMo benötigen Sie einen gültigen Schlüssel, den Sie auf dieser Seite einsehen können, nachdem Sie die Änderungen gespeichert haben'], true);

  public static ALL_PERMISSIONS: AppPermission[] = [
    AppPermission.LOCATION,
    AppPermission.CONTACTS,
    AppPermission.CALENDAR,
    AppPermission.NOTES,
    AppPermission.PHOTOS,
    AppPermission.BLUETOOTH,
    AppPermission.SPEECH_RECOGNITION,
    AppPermission.CAMERA,
    AppPermission.FILESYSTEM,
    AppPermission.BODY_DATA,
    AppPermission.MICROPHONE,
    AppPermission.SINGLE_SIGN_ON,
    AppPermission.SURVEYS,
  ]

  // Verwendung in UI: 'Benötigt Zugriff auf <name>'
  public readonly nameWithPrefix!: string;
  public readonly name!: string;
  public readonly icon!: string;
  public messageBoxType!: MessageBoxType | undefined;
  public messageBoxText!: string[];
  public readonly afterSaveNotChangeable!: boolean;

  private constructor(nameWithPrefix: string, name: string, icon: string, messageBoxType: MessageBoxType | undefined, messageBoxText: string[], afterSaveNotChangeable: boolean) {
    this.nameWithPrefix = nameWithPrefix;
    this.name = name;
    this.icon = icon;
    this.messageBoxType = messageBoxType;
    this.messageBoxText = messageBoxText;
    this.afterSaveNotChangeable = afterSaveNotChangeable;
  }

  public setMessageBoxType(messageBoxType: MessageBoxType | undefined): void {
    this.messageBoxType = messageBoxType;
  }

  public setMessageBoxText(message: string[]): void {
    this.messageBoxText = message;
  }

  public static resetSingleSignOn() : void {
    AppPermission.SINGLE_SIGN_ON.setMessageBoxText(['Für die Einbindung des innovaMo Single-Sign-On benötigen Sie eine Client-ID und ein Client-Secret, die Sie auf dieser Seite einsehen können, nachdem Sie die Änderungen gespeichert haben']);
    AppPermission.SINGLE_SIGN_ON.setMessageBoxType(MessageBoxType.ERROR);
  }

  public static resetSurveys() : void {
    AppPermission.SURVEYS.setMessageBoxText(['Für die Einbindung von Umfragen von innovaMo benötigen Sie einen gültigen Schlüssel, den Sie auf dieser Seite einsehen können, nachdem Sie die Änderungen gespeichert haben']);
    AppPermission.SURVEYS.setMessageBoxType(MessageBoxType.ERROR);
  }
}
