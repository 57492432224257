


































































































































import {
  Component, Vue, Prop, Watch,
} from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
// If you need to reference 'L', such as in 'L.icon', then be sure to
// explicitly import 'leaflet' into your component
import {
  LMap, LTileLayer, LMarker, LTooltip, LPopup, LPolygon, LGeoJson, LIcon, LCircle,
} from 'vue2-leaflet';
import L, { Icon } from 'leaflet';
import TextInput from '@/components/partials/TextInput.vue';
import LoadingAnimation from '@/components/partials/LoadingAnimation.vue';
import Services from '@/assets/services/Services';
import { AppMarker } from '@/apis/projectapi';
import ButtonTabBar from '@/components/partials/ButtonTabBar.vue';
import RoundedButtonFilled from '@/components/partials/RoundedButtonFilled.vue';
import ButtonType from '@/assets/buttonTypes';
import TextHeader from '@/components/partials/TextHeader.vue';
import Header from '@/assets/headers';

const GeoPoint = require('geopoint');
const GeoJson = require('@mapbox/leaflet-pip');

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LPopup,
    LPolygon,
    LGeoJson,
    LIcon,
    LCircle,
    LoadingAnimation,
    TextInput,
    ButtonTabBar,
    RoundedButtonFilled,
    TextHeader,
  },
})
export default class LeafletMap extends Vue {

  @Prop({ default: 500 })
  private height!: number;

  @Prop({ default: () => [] })
  private federalStates!: string[];

  private federalStatesProp = this.federalStates;

  @Prop({ default: () => [] })
  private counties!: string[];

  @Prop({ default: () => [] })
  private projectMarkers!: AppMarker[];

  @Prop({ default: () => [] })
  private positionOfUser!: number[];

  @Prop({ default: false })
  private editable!: boolean;

  @Prop({ default: 5 })
  private zoom!:number;

  @Prop({ default: () => [51.50617819737357, 11.031659487698656] })
  private center!:number[];

  @Prop({ default: 5000 })
  private userPositionCircleRadius!:number;

  @Prop({ default: false })
  private markPositionOfUserOnMap!:boolean;

  private ButtonType = ButtonType;
  private Header = Header;
  private loading: boolean = true;
  private showMarkerCanNotAddError: boolean = false;
  private showMarkerCanNotAddErrorTimeOut: number = 0;
  private showErrorWhenGeoDataCanNotLoaded: boolean = false;
  private tabButtons = ['Bundesländer hinzufügen', 'Landkreise hinzufügen'];
  private tabs: {
    key: string,
    active: boolean
    }[] = [
      { key: 'federalStates', active: true }, { key: 'counties', active: false },
    ];

  // private bounds = L.latLngBounds([[52.10397471104031, -10.953138132258566], [51.91462637924619, 33.10159712140895]]);
  // private maxBounds = L.latLngBounds([[52.10397471104031, -10.953138132258566], [51.91462637924619, 33.10159712140895]]);
  private markers: any = [];
  private get getMarkers() {
    return this.markers;
  }

  private geojson:any = [];
  private countyGeojson: any = [];
  private federalStatesGeojson: any = [];
  private federalStatesData: any = [];
  private germanyData: any = [];
  private countyData: any = [];

  // private url = 'https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png';
  private url = 'https://{s}.tile.openstreetmap.de/{z}/{x}/{y}.png';
  // private attribution = '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors';
  private attribution = '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors';

  // private icon = new Icon({
  //  iconUrl: 'http://localhost:8082/public/img/icons/favicon-16x16.png',
  //  // iconUrl: 'http://icons.iconarchive.com/icons/paomedia/small-n-flat/1024/map-marker-icon.png',
  //  iconSize: [16, 16],
  //  iconAnchor: [16, 37],
  // });
  private awayFromPosition = 5;
  private searchTermCounty = '';
  private countyNumbers: number[] = [];
  private countyId = 0;
  private circle = {
    center: L.latLng(0, 0),
    radius: this.userPositionCircleRadius,
    color: 'red',
  };
  private federalStateNumbers:number[] = [];

  public selectedFederalStates: string[] = [];
  public selectedCounties: string[] = [];
  public selectedMarkers: AppMarker[] = [];

  private get countyDataFeatures() {
    if (this.countyData.features) {
      const t = this.countyData.features.filter((c: any) => c.properties.NAME_3.toLowerCase().includes(this.searchTermCounty.toLowerCase()));
      this.countyId = -1;
      return t;
    }
    return [];
  }

  private get styleFunctionForGermany() {
    const fillColor = '#9B9B9B';
    return () => ({
      weight: 2,
      color: '#9B9B9B',
      opacity: 0.1,
      fillColor: fillColor,
      fillOpacity: 0.3,
    });
  }

  private get styleFunctionForFederalStates() {
    const fillColor = '#006BA8';
    return () => ({
      weight: 2,
      color: '#006BA8',
      opacity: 0.1,
      fillColor: fillColor,
      fillOpacity: 0.3,
    });
  }

  private get styleFunctionForCounties() {
    const fillColor = '#FFA329';
    return () => ({
      weight: 2,
      color: '#FFA329',
      opacity: 0.1,
      fillColor: fillColor,
      fillOpacity: 0.3,
    });
  }

  private get getCircle() {
    return this.circle;
  }

  private get getSelectedFederalStates() {
    return this.selectedFederalStates;
  }

  @Watch('userPositionCircleRadius')
  private onUserPositionCircleRadiusChange() {
    this.circle.radius = this.userPositionCircleRadius;
  }

  @Watch('projectMarkers')
  private onProjectMarkersChange() {
    this.mapProjectMarkersToMapMarkers();
  }

  public userPositionCountry: string = '';
  public userPositionFederalState: string = '';
  public userPositionCounty: string = '';

  private async mounted() {
    try {
      if (this.editable) {
        await this.getAllGeoData();
      }

      if (this.federalStatesProp.length !== 0) {
        if (!this.editable) {
          const response = await Services.projects.downloadSpecificFederalStatesGeoData(this.federalStatesProp);
          this.federalStatesData = response.data;
          this.federalStatesGeojson.push(this.federalStatesData);
        } else {
          const s = { features: this.federalStatesData.features, type: this.federalStatesData.type };
          s.features = s.features.filter((f:any, i:number) => {
            if (this.federalStatesProp.includes(f.properties.name)) {
              this.federalStateNumbers.push(i);
            }
            return this.federalStatesProp.includes(f.properties.name);
          });
          this.federalStatesGeojson.push(s);
        }
        this.selectedFederalStates = this.federalStatesProp;
      }

      if (this.counties.length !== 0) {
        if (!this.editable) {
          const response = await Services.projects.downloadSpecificCountiesGeoData(this.counties);
          this.countyData = response.data;
          this.countyGeojson.push(this.countyData);
        } else {
          const s = { features: this.countyData.features, type: this.countyData.type };
          s.features = s.features.filter((f:any) => {
            if (this.counties.includes(f.properties.NAME_3)) {
              this.countyNumbers.push(f.id);
            }
            return this.counties.includes(f.properties.NAME_3);
          });
          this.countyGeojson.push(s);
        }
        this.selectedCounties = this.counties;
      }

      if (this.projectMarkers.length !== 0) {
        this.mapProjectMarkersToMapMarkers();
        this.selectedMarkers = this.markers;
      }

      this.geojson.push(this.germanyData);
    } catch (e) {
      this.showErrorWhenGeoDataCanNotLoaded = true;
    } finally {
      this.loading = false;
    }

  }

  private async created() {
    if (this.positionOfUser.length === 2) {
      this.circle.center = L.latLng(this.positionOfUser[0], this.positionOfUser[1]);

      if (this.markPositionOfUserOnMap) {
        if (this.markPositionOfUserOnMap) {
          await this.getAllGeoData();
        }

        const isInGermany = GeoJson.pointInLayer([this.positionOfUser[1], this.positionOfUser[0]], L.geoJSON(this.germanyData), false);
        if (isInGermany && isInGermany.length > 0) {
          this.geojson.push(this.germanyData);

          this.userPositionCountry = this.germanyData.features[0].properties.NAME_LOCAL;
        }

        this.federalStatesData.features.forEach((gj: any) => {
          const results = GeoJson.pointInLayer([this.positionOfUser[1], this.positionOfUser[0]], L.geoJSON(gj), false);
          if (results && results.length > 0) {
            this.federalStatesGeojson.push(results[0].feature);
            this.userPositionFederalState = results[0].feature.properties.name;
          }
        });
        const gjLayer = L.geoJSON(this.countyData);
        const results = GeoJson.pointInLayer([this.positionOfUser[1], this.positionOfUser[0]], gjLayer, false);
        if (results && results.length > 0) {
          this.countyGeojson.push(results[0].feature);
          this.userPositionCountry = results[0].feature.properties.NAME_3;
        }
      }
    }
    this.mapProjectMarkersToMapMarkers();
  }

  private changeTab(index: number) {
    this.tabs = this.tabs.map((tab, idx) => ({
      key: tab.key,
      active: idx === index,
    }));
  }

  private selectAllFederalStates() {
    this.federalStatesProp = [];
    this.federalStateNumbers = [];
    this.federalStatesData.features.forEach(((federalState: any, index: number) => {
      this.federalStatesProp.push(federalState.properties.name);
      this.changeFederalStateSelection(index);
    }));
  }

  private changeFederalStateSelection(idx: number) {
    if (this.federalStateNumbers.includes(idx)) {
      this.federalStateNumbers.splice(this.federalStateNumbers.indexOf(idx), 1);
    } else {
      this.federalStateNumbers.push(idx);
    }
    this.federalStatesGeojson = [];
    this.selectedFederalStates = [];
    this.federalStateNumbers.forEach((i: number) => {
      this.federalStatesGeojson.push(this.federalStatesData.features[i]);
      this.selectedFederalStates.push(this.federalStatesData.features[i].properties.name);
    });
  }

  private async getAllGeoData() {
    try {
      const a: any = await Promise.allSettled([
        Promise.resolve(Services.projects.downloadGeoData('germany')),
        Promise.resolve(Services.projects.downloadGeoData('federalStates')),
        Promise.resolve(Services.projects.downloadGeoData('counties')),
      ]);
      this.germanyData = a[0].value.data;
      this.federalStatesData = a[1].value.data;
      this.countyData = a[2].value.data;
    } catch (error) {
      this.loading = false;
      this.showErrorWhenGeoDataCanNotLoaded = true;
    }

  }

  private mapProjectMarkersToMapMarkers() {
    this.markers = this.projectMarkers.map((pm) => ({
      lat: pm.lat,
      lng: pm.lng,
      federalState: pm.federalState,
      county: pm.county,
    }));
  }

  private addMarker(event: any) {
    if (this.editable && !this.showErrorWhenGeoDataCanNotLoaded) {
      let markerCanAdd = false;
      let federalState = '';
      let county = '';
      const c = L.geoJSON(this.geojson!);
      const r = GeoJson.pointInLayer([event.latlng.lng, event.latlng.lat], c, false);
      if (r && r.length > 0) {
        // console.log(r[0].feature.properties.NAME_LOCAL);
        markerCanAdd = true;
      }

      this.federalStatesGeojson.forEach((gj: any) => {
        const gjLayer = L.geoJSON(gj);
        const results = GeoJson.pointInLayer([event.latlng.lng, event.latlng.lat], gjLayer, false);
        if (results && results.length > 0) {
          // console.log(results[0].feature.properties.name);
          federalState = results[0].feature.properties.name;
        }
      });

      const gjLayer = L.geoJSON(this.countyGeojson!);
      const results = GeoJson.pointInLayer([event.latlng.lng, event.latlng.lat], gjLayer, false);
      if (results && results.length > 0) {
        // console.log(results[0].feature.properties.NAME_3);
        county = results[0].feature.properties.NAME_3;
      }

      if (markerCanAdd) {
        this.selectedMarkers.push({
          lat: event.latlng.lat,
          lng: event.latlng.lng,
          federalState: federalState,
          county: county,
        });
        this.markers.push({
          lat: event.latlng.lat,
          lng: event.latlng.lng,
          federalState: federalState,
          county: county,
        });
      } else {
        this.showMarkerCanNotAddError = true;
        window.clearTimeout(this.showMarkerCanNotAddErrorTimeOut);
        this.showMarkerCanNotAddErrorTimeOut = setTimeout(() => this.showMarkerCanNotAddError = false, 5000);
      }
    }
  }

  private removeMarker(index: number) {
    if (this.editable) {
      this.markers.splice(index, 1);
      this.selectedMarkers = this.markers;
    }
  }

  private calculateNearestPosition() {
    const g = new GeoPoint(this.circle.center.lat, this.circle.center.lng);
    const newMarkers: any = [];
    this.markers.forEach((marker: any) => {
      const t = new GeoPoint(marker.lat, marker.lng);
      if (g.distanceTo(t, true) < this.awayFromPosition) {
        newMarkers.push(marker);
      }
    });
    this.markers = newMarkers;
  }

  private changeCountySelection() {
    if (this.countyId !== -1) {
      if (this.countyNumbers.includes(this.countyId) && this.countyId !== -1) {
        this.countyNumbers.splice(this.countyNumbers.indexOf(this.countyId), 1);
      } else {
        this.countyNumbers.push(this.countyId);
      }
      this.countyGeojson = [];

      this.countyNumbers.forEach((i: number) => {
        this.countyGeojson.push(this.countyData.features[i]);
        if (!this.selectedCounties.includes(this.countyData.features[i].properties.NAME_3 as string)) {
          this.selectedCounties.push(this.countyData.features[i].properties.NAME_3 as string);
        }
      });
    }
  }

  private removeSelectedCounty(countyName: string) {
    this.selectedCounties = this.selectedCounties.filter((c) => c !== countyName);

    this.countyGeojson.forEach((c:any) => {
      if (c.features) {
        c.features.forEach((f: any) => {
          if (f.properties.NAME_3 === countyName) {
            this.countyNumbers.splice(this.countyNumbers.indexOf(f.id), 1);
          }
        });
      } else if (c.properties.NAME_3 === countyName) {
        this.countyNumbers.splice(this.countyNumbers.indexOf(c.id), 1);
      }
    });

    this.countyGeojson = this.countyGeojson.filter((c: any) => {
      if (c.properties) {
        return countyName !== c.properties.NAME_3;
      }
      return true;
    }).map((a:any) => {
      const t = a;
      if (t.features) {
        t.features = t.features.filter((c: any) => countyName !== c.properties.NAME_3);
      }
      return t;
    });
  }
}
