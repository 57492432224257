














import {
  Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ButtonTabBar extends Vue {
    @Prop({ required: true })
    private tabButtons!: string[];

    private activeIndex: number = 0;

    @Emit()
    private changeTab(index: number) {
      this.activeIndex = index;
      return index;
    }
}
